import React from 'react'
import Img from 'gatsby-image'
import { css } from 'styled-components'
import {
  CarouselProvider,
  Slider,
  Slide,
} from 'pure-react-carousel'

import {
  space, colors, mediaQueries
} from '../../utils/tokens'

import CarouselButtonGroup from '../CarouselButtonGroup'

const GalleryCarouselBlock = ({
  images,
  carouselProviderProps,
}) => {
  return (
    <CarouselProvider
      naturalSlideWidth={600}
      naturalSlideHeight={400}
      totalSlides={images.length}
      visibleSlides={1}
      infinite
      {...carouselProviderProps}
      css={css({
        position: `relative`,
      })}
    >
      <Slider>
        {images &&
          images.map((image, id) => {
            return (
              <Slide
                index={id}
                key={`slide-${id}`}
                css={css`
                  /** Allows us to use variable width */
                  position: unset !important;
                  height: unset !important;
                  padding-bottom: unset !important;

                  .carousel__inner-slide {
                    position: unset;
                    display: flex;
                    justify-content: center;
                  }
                `}
              >
                <Img
                  fixed={image.fixed}
                  alt={image.title}
                  css={css({
                    margin: `0 auto`,
                  })}
                />
              </Slide>
            )
          })}
      </Slider>
      <div
        css={css({
          marginTop: `${space[3]}px`,
          [mediaQueries.lg]: {
            margin: 0,
            position: `absolute`,
            top: 0,
            left: 0,
            display: `flex`,
            alignItems: `center`,
            height: `100%`,
            width: `100%`,
          },
        })}
      >
        <CarouselButtonGroup
          containerCss={css({
            width: `100%`,
            display: `flex`,
            justifyContent: `center`,
            [mediaQueries.lg]: {
              justifyContent: `space-between`,
            },
          })}
          backButtonCss={css({
            backgroundColor: colors.base,
            color: colors.white,
            borderRadius: `4px`,
          })}
          nextButtonCss={css({
            backgroundColor: colors.base,
            color: colors.white,
            borderRadius: `4px`,
          })}
        />
      </div>
    </CarouselProvider>
  )
}

export default GalleryCarouselBlock